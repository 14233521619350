<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

import { cn } from '@/utils/tw';

import type { WithClassAsProps } from './interface';

import { useCarousel } from './useCarousel';

interface CarouselContentProps {
  contentContainerClass?: HTMLAttributes['class'];
}

defineOptions({ inheritAttrs: false });
const props = defineProps<CarouselContentProps & WithClassAsProps>();

const { carouselRef, orientation } = useCarousel();
</script>

<template>
  <div
    ref="carouselRef"
    :class="cn('w-full overflow-hidden', props.contentContainerClass)"
  >
    <div
      :class="
        cn('flex', orientation === 'horizontal' ? '' : 'flex-col', props.class)
      "
      v-bind="$attrs"
    >
      <slot />
    </div>
  </div>
</template>
