<script setup lang="ts">
import { cn } from '@/utils/tw';

import type { WithClassAsProps } from './interface';

import { useCarousel } from './useCarousel';

const props = defineProps<WithClassAsProps>();

const { orientation } = useCarousel();
</script>

<template>
  <div
    role="group"
    aria-roledescription="slide"
    :class="
      cn(
        'min-w-0 shrink-0 grow-0 basis-full',
        orientation === 'horizontal' ? 'p-2' : 'p-2',
        props.class
      )
    "
  >
    <slot />
  </div>
</template>
